h1 {
  font-weight: 600;
}

@media(max-width:600px){
  h1{
    font-size: 1.2rem;
  }

}

a {
  text-decoration: none;
}

.error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.5rem;
}
.h4 {
  font-size: 1.25rem;
}


.d-none{
  display: none
}

.d-block{
  display: block;
}

.t-center{
  text-align: center;
}
.t-left{
  text-align: left;
}
.t-right{
  text-align: right;
}

.fw-400{
  font-weight: 400;
}
.tt-upper{
  text-transform: uppercase;
}

.f-bold{
  font-weight: bold;
}

.flex-total-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-ver-center{
  display: flex;
  align-items: center;
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
}

.space-between{
  justify-content: space-between;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}



